import AppConfigActionTypes from './AppConfigActionTypes';
import AppConfigReducerInitialState from './AppConfigReducerInitialState';
import { type IAppContextValue } from '../../types/appConfigTypes';
import { type IAction } from '../../types/commonTypes';

export const AppConfigReducer = (state = AppConfigReducerInitialState, action: IAction): IAppContextValue => {
  switch (action.type) {
    case AppConfigActionTypes.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        current_language: action.payload.toString()
      };

    default:
      return state;
  }
};

export default AppConfigReducer;
