import { useQuery, type UseQueryResult } from 'react-query';
import API from '../api/autoSuggest';
import { type IQueryConfig, type IAppContextValue } from '../types/appConfigTypes';
import { isEmpty } from '../utils/utilities';
import { type IAutoSuggestParamsResponse, type IAutoSuggestParamsContextValue } from '../types/autoSuggestTypes';
import { getAutoSuggestQueryString } from '../utils/autoSuggest';
import { useAutoSuggestParamsContext } from './useAutoSuggestParamsContext';

/** Function to add some default config settings to each query */
const getDefaultConfig = (): IQueryConfig => {
  const queryConfig = {
    refetchOnWindowFocus: true,
    // Set to 5 minutes.
    staleTime: 60 * 1000 * 5,
    // Set to 10 minutes.
    cacheTime: 60 * 1000 * 10
  };

  return queryConfig;
};

/** Fetch auto suggest data. */
const fetchAutoSuggestData = async (appConfig: IAppContextValue, autoSuggestParams: IAutoSuggestParamsContextValue): Promise<string[]> => {
  const autoSuggestParamsQueryString = getAutoSuggestQueryString(autoSuggestParams);

  let url = '/';
  if (
    typeof autoSuggestParamsQueryString !== 'undefined' &&
    autoSuggestParamsQueryString.length > 0
  ) {
    url += `?${autoSuggestParamsQueryString}`;
  }
  const res = await API({
    url,
    baseURL: appConfig?.auto_suggest_api_base_url
  });
  return res.data as string[];
};

/** Auto suggest data request. */
export const useAutoSuggestions = (appConfig: IAppContextValue): UseQueryResult<IAutoSuggestParamsResponse, Error> => {
  const { autoSuggestParams } = useAutoSuggestParamsContext();
  const queryConfig = getDefaultConfig();

  // Only execute the request if a search term is present.
  let searchTermAvailable = false;
  if (!isEmpty(autoSuggestParams.q)) {
    searchTermAvailable = true;
  }
  queryConfig.enabled = searchTermAvailable;

  return useQuery(['auto-suggest', appConfig, autoSuggestParams], async () => await fetchAutoSuggestData(appConfig, autoSuggestParams), queryConfig);
};

export default useAutoSuggestions;
