import React from 'react';
import { SearchForm } from '../SearchForm/SearchForm';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { ConditionalView } from '../../UI/ConditionalView/ConditionalView';
import { SetUpError } from '../SetUpError/SetUpError';
import { AutoSuggestWrapper } from '../AutoSuggestWrapper/AutoSuggestWrapper';
import { useAutoSuggestions } from '../../hooks/useAutoSuggestions';
import { isEmpty } from '../../utils/utilities';

export const SearchBarWrapper = (): JSX.Element => {
  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  const {
    data
  } = useAutoSuggestions(appConfig);

  let appInitialised = false;

  if (appConfig !== undefined) {
    appInitialised = true;
  }

  return (
    <>
      <ConditionalView condition={!appInitialised}>
        <SetUpError />
      </ConditionalView>
      <ConditionalView condition={appInitialised}>
        <SearchForm />
        <ConditionalView condition={!isEmpty(data)}>
          <AutoSuggestWrapper/>
        </ConditionalView>
      </ConditionalView>
    </>
  );
};

export default SearchBarWrapper;
