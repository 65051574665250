import { type IAction } from '../../types/commonTypes';
import { type IAutoSuggestParamsContextValue } from '../../types/autoSuggestTypes';
import SearchActionTypes from './AutoSuggestParamsActionTypes';
import AutoSuggestParamsReducerInitialState from './AutoSuggestParamsReducerInitialState';

export const AutoSuggestParamsReducer = (state = AutoSuggestParamsReducerInitialState, action: IAction): IAutoSuggestParamsContextValue => {
  switch (action.type) {
    case SearchActionTypes.SET_CATALOG_VIEWS:
      return {
        ...state,
        catalog_views: action.payload.toString()
      };
    case SearchActionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        q: action.payload.toString()
      };
    case SearchActionTypes.SET_AUTOSUGGEST_MODAL_STATE:
      return {
        ...state,
        autosuggest_modal_open: Boolean(action.payload)
      };
    default:
      return state;
  }
};

export default AutoSuggestParamsReducer;
