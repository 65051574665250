import React from 'react';
import { useTranslation } from 'react-i18next';

export const SetUpError: React.FC = () => {
  // Get translations for component text.
  const { t } = useTranslation('common');

  return (
    <>
      <h2>{t('setup.error.title')}</h2>
      {<p>{t('setup.error.product')}</p>}
    </>
  );
};

export default SetUpError;
