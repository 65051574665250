/* eslint-disable no-undef */
import AutoSuggestParamsReducerInitialState from '../reducers/AutoSuggestParamsReducer/AutoSuggestParamsReducerInitialState';
import { type IAutoSuggestParamsContextValue } from '../types/autoSuggestTypes';
import { isEmpty } from './utilities';

export const getAutoSuggestParams = (): IAutoSuggestParamsContextValue => {
  const autoSuggestParams = AutoSuggestParamsReducerInitialState;

  if (typeof window.drupalSettings !== 'undefined' &&
    !isEmpty(window.drupalSettings.search_bar_app)
  ) {
    const { account_id: accountId, catalog_views: catalogViews } = window.drupalSettings.search_bar_app;
    autoSuggestParams.account_id = accountId;
    autoSuggestParams.catalog_views = catalogViews;
  }

  return autoSuggestParams;
};

/**
 * A function format the autoSuggest object into a query string.
 *
 * @param {*} autoSuggest
 */
export const getAutoSuggestQueryString = (autoSuggest: IAutoSuggestParamsContextValue): string => Object.keys(autoSuggest)
  .filter((key) => (typeof autoSuggest[key as keyof IAutoSuggestParamsContextValue] !== 'undefined' && autoSuggest[key as keyof IAutoSuggestParamsContextValue].toString().length > 0)).map((key) => {
    let autoSuggestQuery = '';
    const value = autoSuggest[key as keyof IAutoSuggestParamsContextValue] as string;
    autoSuggestQuery = `${key}=${value}`;
    return autoSuggestQuery;
  })
  .join('&');
