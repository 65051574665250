import { isEmpty } from './utilities';
import {
  type IAutoSuggestQuerySuggestion,
  type IAutoSuggestParamsResponse,
  type IAutoSuggestSearchSuggestions,
  type IAutoSuggestAttributeSuggestion
} from '../types/autoSuggestTypes';

/** Process auto suggest results to get useful information. */
export const getAutoSuggestQuerySuggestions = (results: IAutoSuggestParamsResponse): IAutoSuggestQuerySuggestion[] => {
  let data: IAutoSuggestQuerySuggestion[] = [];

  results.suggestionGroups.forEach((value) => {
    if (!isEmpty(value) && !isEmpty(value.querySuggestions)) {
      data = value.querySuggestions;
    }
  });

  return data;
};

/** Process auto suggest results to get useful information. */
export const getAutoSuggestAttributeSuggestions = (results: IAutoSuggestParamsResponse): IAutoSuggestAttributeSuggestion[] => {
  let data: IAutoSuggestAttributeSuggestion[] = [];

  results.suggestionGroups.forEach((value) => {
    if (!isEmpty(value) && !isEmpty(value.attributeSuggestions)) {
      data = value.attributeSuggestions;
    }
  });

  return data;
};

/** Process auto suggest results to get useful information. */
export const getAutoSuggestSearchSuggestions = (results: IAutoSuggestParamsResponse): IAutoSuggestSearchSuggestions[] => {
  let data: IAutoSuggestSearchSuggestions[] = [];

  results.suggestionGroups.forEach((value) => {
    if (!isEmpty(value) && !isEmpty(value.searchSuggestions)) {
      data = value.searchSuggestions;
    }
  });

  return data;
};

/** Process auto suggest results to get useful information. */
export const getAutoSuggestOriginalQuery = (results: IAutoSuggestParamsResponse): string => {
  let data = '';

  if (!isEmpty(results.queryContext) && !isEmpty(results.queryContext.originalQuery)) {
    data = results.queryContext.originalQuery;
  }

  return data;
};
