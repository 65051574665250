import React, {
  useMemo,
  useEffect,
  useReducer
} from 'react';
// Default currency $.
import numeral from 'numeral';
// Add £.
import 'numeral/locales/en-gb';
// Add €.
import '../../locales/numeral/en-ie';
import { node } from 'prop-types';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { type IChildrenProps } from '../../types/commonTypes';
import { getAutoSuggestParams } from '../../utils/autoSuggest';
import { AutoSuggestParamsReducer } from '../../reducers/AutoSuggestParamsReducer';
import { AutoSuggestContext } from '../../contexts/AutoSuggestContext/AutoSuggestContext';

export const AutoSuggestParamsProvider: React.FC<IChildrenProps> = ({ children }) => {
  // Get the site config from one of the expected locations
  // and configure the autosuggest search params. For Drupal websites, this
  // will be the Drupal settings.
  const initialAutoSuggestParams = getAutoSuggestParams();

  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  // Store the current product search parameters in state.
  const [autoSuggestParams, setAutoSuggestParams] = useReducer(
    AutoSuggestParamsReducer,
    initialAutoSuggestParams
  );

  // When the lang changes (initial selection), we need to update the related states.
  useEffect(() => {
    // Update the currency formatting.
    let numeralLocale = '';
    if (appConfig.current_language === 'en') {
      numeralLocale = 'en-gb';
    } else if (appConfig.current_language === 'de-de' || appConfig.current_language === 'en-ie') {
      numeralLocale = 'en-ie';
    }
    if (numeralLocale.length > 0) {
      numeral.locale(numeralLocale);
    } else {
      numeral.reset();
    }
  }, [appConfig.current_language]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const AutoSuggestContextValue = useMemo(() => ({
    autoSuggestParams,
    setAutoSuggestParams
  }), [autoSuggestParams]);

  return (
    <AutoSuggestContext.Provider value={AutoSuggestContextValue}>{children}</AutoSuggestContext.Provider>
  );
};

AutoSuggestParamsProvider.propTypes = {
  children: node.isRequired
};

AutoSuggestParamsProvider.defaultProps = {};

export default AutoSuggestParamsProvider;
