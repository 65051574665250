import React from 'react';
import PropTypes from 'prop-types';

interface IProps {
  condition: boolean | undefined
  children?: React.ReactNode
}

export const ConditionalView = (props: IProps): JSX.Element | null => {
  const { condition, children } = props;

  if (typeof condition === 'undefined' || !condition) {
    return null;
  }

  return (
    <>
      { children }
    </>
  );
};

// Specify the default values for the required props.
ConditionalView.defaultProps = {
  condition: false,
  children: ''
};

ConditionalView.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.node
};

export default ConditionalView;
