import React from 'react';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { useAutoSuggestions } from '../../hooks/useAutoSuggestions';
import { getAutoSuggestAttributeSuggestions } from '../../utils/autoSuggestResults';
import { type IAutoSuggestAttributeSuggestion } from '../../types/autoSuggestTypes';
import { isEmpty } from '../../utils/utilities';
import { useTranslation } from 'react-i18next';
import { createUrl } from '../../utils/appConfig';

export const CategorySuggestions = (): JSX.Element | null => {
  // Get translations for component text.
  const { t } = useTranslation('common');

  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  const {
    isSuccess,
    data
  } = useAutoSuggestions(appConfig);

  if (!isSuccess) {
    return null;
  }

  if (isEmpty(data)) {
    return null;
  }

  const suggestions: IAutoSuggestAttributeSuggestion[] = getAutoSuggestAttributeSuggestions(data);

  if (isEmpty(suggestions)) {
    return null;
  }

  return (
    <div>
        <h3>{t('search.related_categories')}</h3>
        <ul className="">
          {
            suggestions.map((suggestion: IAutoSuggestAttributeSuggestion) => {
              return (
                <li key={suggestion.value} className="">
                  <a href={createUrl(appConfig.search_page_url + '?q=' + suggestion.name)}><span>{suggestion.name}</span></a>
                </li>
              );
            })
          }
        </ul>
    </div>
  );
};

export default CategorySuggestions;
