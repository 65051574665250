import React from 'react';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { useAutoSuggestions } from '../../hooks/useAutoSuggestions';
import { getAutoSuggestQuerySuggestions } from '../../utils/autoSuggestResults';
import { type IAutoSuggestQuerySuggestion } from '../../types/autoSuggestTypes';
import { isEmpty } from '../../utils/utilities';
import { createUrl } from '../../utils/appConfig';

export const AutoSuggestions = (): JSX.Element | null => {
  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  const {
    isSuccess,
    data
  } = useAutoSuggestions(appConfig);

  if (!isSuccess) {
    return null;
  }

  if (isEmpty(data)) {
    return null;
  }

  const suggestions: IAutoSuggestQuerySuggestion[] = getAutoSuggestQuerySuggestions(data);

  if (isEmpty(suggestions)) {
    return null;
  }

  return (
    <div>
        <ul>
          {
            suggestions.map((suggestion: IAutoSuggestQuerySuggestion) => {
              return (
                <li key={suggestion.query}>
                  <a href={createUrl(appConfig.search_page_url + '?q=' + suggestion.query)}><span>{suggestion.displayText}</span></a>
                </li>
              );
            })
          }
        </ul>
    </div>
  );
};

export default AutoSuggestions;
