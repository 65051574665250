import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../utils/utilities';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import AutoSuggestParamsActionTypes from '../../reducers/AutoSuggestParamsReducer/AutoSuggestParamsActionTypes';
import { useAutoSuggestParamsContext } from '../../hooks/useAutoSuggestParamsContext';
import { ReactComponent as SearchIcon } from '../../styles/search.svg';
import styles from './SearchForm.module.scss';
import { createUrl } from '../../utils/appConfig';

interface FormElements extends HTMLFormControlsCollection {
  searchInput: HTMLInputElement
}
interface SearchFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

export const SearchForm = (): JSX.Element => {
  // Get translations for component text.
  const { t } = useTranslation('common');

  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  const {
    setAutoSuggestParams
  } = useAutoSuggestParamsContext();

  const redirectToSearchPage = (searchInput: string): void => {
    if (!isEmpty(appConfig.search_page_url)) {
      window.location.href = createUrl(appConfig.search_page_url + '?q=' + searchInput);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchInput = event.target.value;

    setAutoSuggestParams({
      type: AutoSuggestParamsActionTypes.SET_SEARCH_TERM,
      payload: searchInput
    });
  };

  const handleSubmit = (event: React.FormEvent<SearchFormElement>): void => {
    event.preventDefault();
    const searchInput = event.currentTarget.elements.searchInput.value;

    if (!isEmpty(searchInput)) {
      redirectToSearchPage(searchInput);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.searchWrapper}>
      <SearchIcon className={styles.searchIcon} />
      <input
        className={styles.searchText}
        id="searchInput"
        placeholder={t('search.placeholder').toString()}
        type="text"
        onChange={handleChange}
      />
      <input className={styles.searchBtn} type="submit" value={t('search.submit_label').toString()}/>
    </form>
  );
};

export default SearchForm;
