import Cookies from 'js-cookie';

// Bloomreach recommends to generate unique, random values of 13 digits.
const getRequestId = (): number => {
  const min = 1000000000000;
  const max = 9999999999999;
  const requestId = Math.floor(Math.random() * (max - min + 1)) + min;

  return requestId;
};

const AutoSuggestParamsReducerInitialState = {
  account_id: (process.env.REACT_APP_ACCOUNT_ID ?? ''),
  catalog_views: (process.env.REACT_APP_CATALOG_VIEWS ?? ''),
  request_type: 'suggest',
  q: '',
  autosuggest_modal_open: true,
  request_id: getRequestId(),
  url: document.location.href,
  _br_uid_2: Cookies.get('_br_uid_2') ?? ''
};

export default AutoSuggestParamsReducerInitialState;
