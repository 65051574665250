import React, { useEffect, useState } from 'react';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import styles from './SpecialOffers.module.scss';
import { createUrl, getLang } from '../../utils/appConfig';

export const SpecialOffers = (): JSX.Element | null => {
  const [offerDescription, setOfferDescription] = useState('Free dining & drinks');
  const [offerDescriptionDetails, setOfferDescriptionDetails] = useState('');

  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  // Set text language
  useEffect(() => {
    if (appConfig.current_language === 'de-de') {
      setOfferDescription('5% Rabatt auf Orlando Tickets mit Code:');
    } else if (appConfig.current_language === 'en' || appConfig.current_language === 'en-ie') {
      setOfferDescription('15% OFF Orlando Tickets with code');
    } else {
      setOfferDescription('5% OFF Orlando Tickets with code');
    }
  }, [appConfig.current_language]);

  return (
      <>
        <div className={`${styles.specialOffersContainer} specialOffersContainer ` +
        (appConfig.current_language === 'en' ? 'styles.specialOffersContainerEn' : '') + ' ' +
        (appConfig.current_language === 'de-de' ? 'styles.specialOffersContainerDe' : '')}>
          <a href={createUrl('node/275709')} className={styles.specialOffersLink} >
            <div className={styles.specialOffers}>
              <div>
                <strong className={styles.offerDescription}>
                  {offerDescription}
                  <span>{offerDescriptionDetails}</span>
                </strong>
                <span className={styles.offerCode}>BLACKFRIDAY</span>
              </div>
            </div>
          </a>
        </div>
      </>
  );
};

export default SpecialOffers;
