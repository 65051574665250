import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SearchBarWrapper } from './components/SearchBarWrapper/SearchBarWrapper';
import { AppConfigProvider } from './providers/AppConfigProvider/AppConfigProvider';
import { AutoSuggestParamsProvider } from './providers/AutoSuggestParamsProvider/AutoSuggestParamsProvider';

// // Initialise React Query Client.
const queryClient = new QueryClient();

const App = (): JSX.Element => (
  <QueryClientProvider client={queryClient}>
     <AppConfigProvider>
        <AutoSuggestParamsProvider>
          <SearchBarWrapper />
        </AutoSuggestParamsProvider>
     </AppConfigProvider>
    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
);

export default App;
