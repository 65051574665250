import { type IAutoSuggestParamsResponse } from '../types/autoSuggestTypes';

export const isEmpty = (variable: object | string[] | string | number | IAutoSuggestParamsResponse | undefined): boolean => {
  const isEmpty = false;

  if (typeof variable === 'undefined' || variable === '' || variable === null) {
    return true;
  }

  if (typeof variable === 'object' && Object.keys(variable).length <= 0) {
    return true;
  }

  return isEmpty;
};
