/* eslint-disable no-undef */
import AppConfigReducerInitialState from '../reducers/AppConfigReducer/AppConfigReducerInitialState';
import { isEmpty } from './utilities';
import { type IAppContextValue } from '../types/appConfigTypes';

/** Get the app config. */
export const getAppConfig = (): IAppContextValue => {
  // Get the site config from one of the expected locations.
  // For Drupal websites, this will be the Drupal settings.
  let appConfig = AppConfigReducerInitialState;

  if (
    typeof window !== 'undefined' &&
    typeof window.drupalSettings !== 'undefined' &&
    !isEmpty(window.drupalSettings.search_bar_app)
  ) {
    appConfig = window.drupalSettings.search_bar_app;
  }

  return appConfig;
};

/** Get the auto suggest API Base Url from the the app config. */
export const getAutoSuggestApiBaseUrl = (): string => {
  let apiBaseUrl = '';
  const appConfig = getAppConfig();
  if (
    typeof appConfig !== 'undefined' &&
    appConfig.auto_suggest_api_base_url !== 'undefined'
  ) {
    apiBaseUrl = appConfig.auto_suggest_api_base_url;
  }

  return apiBaseUrl;
};

/** Create drupal url. */
export const createUrl = (path: string): string => {
  let url = path;
  if (
    typeof window !== 'undefined' &&
    typeof window.Drupal !== 'undefined' &&
    typeof window.Drupal.url !== 'undefined'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    url = window.Drupal.url(path) as string;
  }

  return url;
};

/** Get drupal lang */
export const getLang = (): string => {
  let lang = '';
  if (
    typeof window !== 'undefined' &&
    typeof window.drupalSettings !== 'undefined' &&
    typeof window.drupalSettings.path !== 'undefined' &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    typeof window.drupalSettings.path.currentLanguage !== 'undefined'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    lang = window.drupalSettings.path.currentLanguage;
  }

  return lang;
};
